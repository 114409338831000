import { awaitTimeout } from "@solvari/common-fe/helpers";

import "./headerSearch.css";
import "./headerTop.css";

function initHeaderTop() {
  const headerSearchButton = document.querySelector(".s-header__search-button");
  const headerSearchPopup = document.querySelector(".s-header__search-popup");

  const headerSearchBar = document.querySelector(".s-header-search");
  const headerSearchInput = document.querySelector<HTMLInputElement>(
    ".s-header-search__input",
  );

  headerSearchButton?.addEventListener("click", () => {
    headerSearchPopup?.classList.add("s-header__search-popup--open");
    headerSearchInput?.focus();
    void animatePopup();
  });

  headerSearchBar?.addEventListener("focusout", () => {
    headerSearchPopup?.classList.remove("s-header__search-popup--open");
    void animatePopup();
  });

  async function animatePopup() {
    headerSearchPopup?.classList.add("s-header__search-popup--animating");
    await awaitTimeout(200);
    headerSearchPopup?.classList.remove("s-header__search-popup--animating");
  }
}

initHeaderTop();
