import "./footerMenu.css";

const footerTitles = document.querySelectorAll<HTMLElement>(
  ".s-footer-menus__menu__title",
);

footerTitles.forEach((footerTitle) => {
  footerTitle.addEventListener("click", () => {
    const menuIndicator = footerTitle.querySelector("span");
    const menuItems = footerTitle.parentElement?.querySelector<HTMLElement>(
      ".s-footer-menus__menu__items",
    );

    if (!menuItems || !menuIndicator) {
      return;
    }

    const isClosed = menuItems.clientHeight === 0;

    menuItems.style.height = isClosed ? `${menuItems.scrollHeight}px` : "0px";
    menuIndicator.style.transform = isClosed
      ? "rotate(180deg)"
      : "rotate(0deg)";
  });
});
