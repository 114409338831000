import "./benefits.css";

setInterval(() => {
  cycleBenefit();
}, 4000);

function cycleBenefit() {
  const currentBenefit: HTMLElement | null = document.querySelector(
    ".s-benefits__content__slide--showing",
  );
  if (!currentBenefit) {
    return;
  }
  const benefitId = parseInt(currentBenefit.getAttribute("data-benefit")!);
  let nextBenefitId = benefitId + 1;
  if (benefitId === 4) {
    nextBenefitId = 1;
  }
  const nextBenefit = document.querySelector(
    `[data-benefit="${nextBenefitId}"]`,
  );
  if (!nextBenefit) {
    return;
  }
  currentBenefit.classList.remove("s-benefits__content__slide--showing");
  currentBenefit.classList.add("s-benefits__content__slide--hidden");
  setTimeout(() => {
    currentBenefit.classList.remove("s-benefits__content__slide--hidden");
    nextBenefit.classList.add("s-benefits__content__slide--showing");
  }, 300);
}

const loader = document.querySelector(".s-benefits__loader");
if (loader) {
  loader.remove();
}
